import { useNuxtApp, defineNuxtRouteMiddleware } from 'nuxt/app'
import { AdminMenuData, MenuData } from '~/constants/menu'
import { ROLES } from '~/constants/roles'

export default defineNuxtRouteMiddleware((to) => {
  const nuxtApp = useNuxtApp()

  let roles
  /* Account page */
  if (to.path === '/account/') return

  if (to.fullPath.startsWith('/docs/')) {
    roles = [ROLES.DOCS_READ]
  } else if (to.fullPath.startsWith('/admin/')) {
    /* Admin pages */
    const adminSection = to.fullPath.split('/')[2].slice(0, -1)
    roles = Object.values(AdminMenuData).find((item) => item.alias.startsWith(adminSection)).roles
  } else {
    /* Rest of the pages */
    const readSection = to.fullPath.split('/')[1].slice(0, -1)
    roles = Object.values(MenuData).find((item) => item.alias.startsWith(readSection)).roles
  }
  if (!nuxtApp.$authorization.hasAccess(roles)) abortNavigation({ statusCode: 403 })
})
